









import Vue from "vue";
import { mapState } from "vuex";
import VuePdfApp from "vue-pdf-app";

export default Vue.extend({
  components: {
    VuePdfApp
  },
  data() {
    return {
      config: {
        toolbar: {
          toolbarViewerLeft: {
            findbar: false
          },
          toolbarViewerMiddle: {
            scaleSelectContainer: false
          },
          toolbarViewerRight: false
        },
        secondaryToolbar: false
      }
    };
  },
  computed: {
    ...mapState("app", {
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss"
    }),
    pdf(): { src: string; name: string } {
      const id = this.$route.params.id;
      const path = this.$route.path;
      const pdfs = {
        tws: { src: this.oss.pdf_cyberblade, name: "TWS User Manual" },
        "/cyberblade/manual": {
          src: this.oss.pdf_cyberblade_base_edition,
          name: "CYBERBLADE BASE EDITION"
        },
        "/cyberblade/manual/": {
          src: this.oss.pdf_cyberblade_base_edition,
          name: "CYBERBLADE BASE EDITION"
        },
        "am-afa": { src: this.oss.pdf_am_afa, name: "AM AFA User Manual" },
        "am-afa-r2": {
          src: this.oss.pdf_am_afa_build_r2,
          name: "AM AFA R2 拆装说明 Build Guide"
        },
        "65-less": {
          src: this.oss.pdf_am_65_less,
          name: "AM 65 Less User Manual"
        },
        "am-65-less": {
          src: this.oss.pdf_am_65_less,
          name: "AM 65 Less User Manual"
        },
        "am-65-less-r2": {
          src: this.oss.pdf_am_65_less_r2,
          name: "AM 65 Less User Manual"
        },
        "am-compact-touch": {
          src: this.oss.pdf_am_65_less,
          name: "AM Compact Touch User Manual"
        },
        "cyberboard-r2_5": {
          src: this.oss.pdf_cyberboardR2_5,
          name: "CYBERBOARD R2.5 User Manual"
        },
        "cyberboard-r4": {
          src: this.oss.pdf_cyberboard_r4,
          name: "CYBERBOARD R4 User Manual"
        },
        "cyberboard-glacier-r2": {
          src: this.oss.pdf_cyberboard_glacier_r2,
          name: "CYBERBOARD Glacier R2 User Manual"
        },
        "black-diamond-75": {
          src: this.oss.pdf_black_diamond_75,
          name: "Black diamond 75 User Manual"
        },
        "am-relic-80": {
          src: this.oss.pdf_am_relic_80,
          name: "AM Relic 80 User Manual"
        },
        "am-rgb-65": {
          src: this.oss.pdf_am_rgb_65,
          name: "AM RGB 65 User Manual"
        }
      };
      return (pdfs as any)[id || path];
    }
  },
  mounted() {
    document.title = this.pdf.name || "User Manual";
  }
});
